.fontConcecionaria{
    font-size: 12px;
    color:#3d5170;
}
.fontInfoCar{
    font-size: 21px;
    font-weight: 700;
    color:#3d5170;
}
.fontPriceCar{
    font-size: 19px;
    font-weight: 500;
    background: #ff0042;
    background: linear-gradient(
        124deg,
        rgba(255, 0, 66, 1) 0%,
        rgba(233, 127, 0, 1) 100%
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    .fontPriceCarToPrint{
        color:#ff0042;
        font-size: 19px;
        font-weight: 500;
}
.fontLocationCar{
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 0;
    color:#3d5170;
}
.fontTextSecondary{
    font-family: roboto;
    font-size: 13px;
    color:#3d5170;
    font-weight: 400;
}
.fontCreateData{
    font-size: 12px;
    font-weight: 400;
    position: absolute;
    right: 0;
    color:#3d5170;
    top: 0;

}
.fontDealerInfo{
    font-size: 19px;
    font-weight: 700;
    color:#3d5170;
}
.fontDealerInfoHorus{
    font-size: 18px;
    color:#3d5170;
    font-weight: 400;
}
.fontDealerInfoHorusDays{
    font-size: 13px;
    font-weight: 400;
    color:#3d5170;
}
.containerInfoCarSectionDetail{
    position: relative;
    border-radius: 7px;
    border: 1px solid rgba(26, 32, 44, 0.3);
    padding: 25px;
    margin-bottom: 20px;
}
.containerInfoDealerSectionDetail{
    border-radius: 7px;
    border: 1px solid rgba(26, 32, 44, 0.3);
    padding: 25px;
    margin-bottom: 20px;
    
}
.button-primary-info-dealer{
    width: 100%;
    height: 63px;
    border-radius: 7px;
    font-size: 19px;
    font-weight: bold;
}


@media (max-width: 575.98px) {
    .containerInfoCarSectionDetail{
        width: 100%;
    }
    .infoDealerSectionDetail{
        margin-top: 25px;
        width: 100%;
    }

}
