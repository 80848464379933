.image-gallery img {
    width: 100% !important;
    height: 65px !important;
  }
  
  .image-gallery-slide img {
    width: 100% !important;
    height: 550px !important;
    max-height: 80vh !important;
    object-fit: cover !important;
    overflow: hidden !important;
    object-position: center center !important;
  }
  
  .fullscreen .image-gallery-slide img {
    width: 100% !important;
    height: 90% !important;
    object-fit: cover !important;
    object-position: center center !important;
    max-height: 90vh !important;
  }
  .fullscreen {
    background: white !important;
    width: 100% !important;
    height: 100vh !important;
  }
 
  
@media (min-width: 320px) and (max-width: 767px) {
  .sliderImageSectionDetail{
    width: 100%;
  }
  }