.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-group {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.right_bottom_po {
  float: right !important;

  padding: 0.45rem 1.45rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;

  color: #fff;
  border-color: #032445;
  background-color: #032445;
  box-shadow: none;

  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  border: 1px solid transparent;
}

.left_bottom_po {
  padding: 0.45rem 1.45rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 100px;

  color: #fff;
  border-color: #032445;
  background-color: #032445;
  box-shadow: none;

  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,
    sans-serif;
  border: 1px solid transparent;
}
.dropdown-menu {
  padding: 0;
}
.react-card-flip {
  box-shadow: 0 0.46875rem 2.1875rem rgba(90, 97, 105, 0.1),
    0 0.9375rem 1.40625rem rgba(90, 97, 105, 0.1), 0 0.25rem 0.53125rem rgba(90, 97, 105, 0.12),
    0 0.125rem 0.1875rem rgba(90, 97, 105, 0.1);
  border-radius: 0.625rem;
  background: rgba(90, 97, 105, 0.1);
  margin: 0px !important;
}

.colPublish {
  text-align: center;
  padding: 25px;
}

.csshide {
  opacity: 0.3;

  transition: visibility 0s linear 0.33s, opacity 0.33s linear;
}

.cssshow {
  visibility: visible;
  opacity: 1;

  transition-delay: 0s;
}

.cssback {
  background: #aeb1b71c;
}
.pontier {
  cursor: pointer;
  text-decoration: underline;
}

.hide {
  display: none;
}
.textValidation {
  color: red;
  font-size: 10px;
}
.textAviso {
  color: #f16717;
  font-size: 10px;
}
