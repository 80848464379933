* {
  /* overflow-x:hidden;*/
}
.tops {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  padding-top: 80px;

  background-color: #032445;
}

.sin-bottom {
  margin-bottom: 0px !important;
}

@media (maxWidth: 767px) {
  .desktop-only {
    display: none;
  }
}

@media (min-width: 768px) {
  .mobile-only {
    display: none;
  }
}

.icon-body {
  width: 100px;
  padding: 15px;
  border-radius: 60%;
  color: white;
  margin: 0 auto;
  -webkit-box-shadow: 0px 10px 50px -18px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 50px -18px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 10px 50px -18px rgba(0, 0, 0, 0.75);
  margin-bottom: 15px;
  margin-top: 20px;
}
.icon-body-white {
  width: 90px;
  height: 90px;
  padding: 15px;
  border-radius: 60%;
  border: 5px solid white;
  color: white;
  margin: 0 auto;
  margin-bottom: 15px;
}

.margin-doble {
  margin-right: 5px;
  margin-left: 5px;
  border-radius: 60px !important;
}

.inline-form {
  display: inline !important;
}

.texto_verde {
  color: #17c671 !important;
}
.icon-color-rose {
  background: #f56eab;
}

.icon-color-green {
  background: #7cc576;
}

.icon-color-blue {
  background: #49b5e7;
}

.side {
  padding: 110px 0;
}

.logo-cca-img {
  maxWidth: 200px;
  width: 100%;
}

.letra {
  padding-top: 20px;
  color: #ff9547;
}

.texto {
  padding-top: 10px;
  color: #888888;
}

.pantalla1 {
  width: 100%;
  height: auto;
}

.container-sin-derecha {
  margin-right: 0px;
  padding-right: 0px !important;
}

.container-con-padding-top {
  padding-top: 80px;
}

.centerized {
  text-align: center;
}

.iconos {
  width: 4rem !important;
  height: 4rem !important;
}

.iconos-con {
  width: 3rem !important;
  height: 3rem !important;
}

.nav-icon {
  width: 2rem !important;
  height: 2rem !important;
  color: white;
}

.centrar-verticalmente {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0px;
}

.social_img {
  width: 100%;
}

.gray_bar {
  background-color: #484a47;
  color: #eae7e7;
  font-size: 26px;
  padding: 40px 0;
}

.pk {
  line-height: 33px;
}

.naranja {
  color: #ff9547;
}

.footer {
  background: url("/img/background.gif");
  background-size: cover;
  min-height: 100vh;
  height: 100%;
}

.texto_blanco {
  color: white;
}

.fixed_navbar {
  position: fixed !important;
  width: 100%;
  z-index: 10000;
  background: #032445 !important;
}

.border-white {
  border: 1px solid white;
}

.border-verde {
  border: 1px solid #17c671 !important;
}

.c-blanco {
  color: white !important;
}
.collapsible {
  z-index: 50;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  transform: translateX(0%);
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
  width: 600px;
  position: absolute;

  right: -590px;
  opacity: 0;
}

.collapsible.open {
  right: 100px;
  opacity: 1;
}

#nav-icon1 {
  cursor: pointer;
  width: 34px;
  height: 40px;
  position: relative;
  margin: 10px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
}

#nav-icon1 span,
#nav-icon3 span,
#nav-icon4 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #ffffff;
  border-radius: 9px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
  top: 0px;
}

#nav-icon1 span:nth-child(2) {
  top: 6px;
}

#nav-icon1 span:nth-child(3) {
  top: 12px;
}

#nav-icon1.open span:nth-child(1) {
  top: 10px;
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
}

#nav-icon1.open span:nth-child(2) {
  opacity: 0;
  left: -60px;
}

#nav-icon1.open span:nth-child(3) {
  top: 10px;
  -webkit-transform: rotate(-135deg);
  -moz-transform: rotate(-135deg);
  -o-transform: rotate(-135deg);
  transform: rotate(-135deg);
}
