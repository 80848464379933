.shards-landing-page--1 .welcome {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url("/imagesHome/agency-landing/welcome-cover.jpg") no-repeat
    center center fixed;
  background-size: cover;
}
img.big-logo{
  
   max-width:85%;
}
.shards-landing-page--1 .welcome:before {
  position: absolute;
  z-index: 0;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.8;
  background: #007bff;
}
@media (max-width: 767.98px) {
  .shards-landing-page--1 .welcome .inner-wrapper {
    text-align: center;
  }
}
@media (max-width: 400px) {
  .shards-landing-page--1 .welcome .welcome-heading {
    font-size: 2.415rem;
  }
}
.shards-landing-page--1 .section-title {
  position: relative;
}
.shards-landing-page--1 .section-title:after {
  content: "";
  width: 30px;
  height: 2px;
  background: #007bff;
  position: absolute;
  left: 50%;
  margin-left: -15px;
  bottom: -20px;
}
.shards-landing-page--1 .feature .icon {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(33, 37, 41, 0.1), 0 7px 14px rgba(33, 37, 41, 0.1);
  font-size: 27px;
}
.shards-landing-page--1 .section {
  border-bottom: 1px solid #eaebed;
}
.shards-landing-page--1 .section-invert {
  background: #f9fafc;
}
.shards-landing-page--1 .testimonials .avatar {
  max-width: 80px;
  overflow: hidden;
}
.shards-app-promo-page--1 .welcome {
  position: relative;
  height: 100vh;
  min-height: 700px;
  background: url(/imagesHome/app-promo/welcome-cover.jpg) no-repeat center
    center fixed;
  background-size: cover;
  margin-bottom: -1px;
}
.shards-app-promo-page--1 .welcome:before {
  position: absolute;
  z-index: 0;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.6;
  background: #212529;
}
@media (max-width: 991.98px) {
  .shards-app-promo-page--1 .welcome {
    min-height: auto;
  }
  .shards-app-promo-page--1 .welcome .header-social-icons {
    display: none;
  }
}
@media (max-width: 400px) {
  .shards-app-promo-page--1 .welcome .welcome-heading {
    font-size: 2.415rem;
  }
}
.shards-app-promo-page--1 .welcome .iphone-mockup {
  max-width: 85%;
}
.shards-app-promo-page--1 .section-title.underline--left:after {
  left: 0;
  margin-left: 0;
}
.shards-app-promo-page--1 .app-screenshot {
  background: url(/imagesHome/app-promo/features-background.jpg) no-repeat
    center center fixed;
  background-size: cover;
}
.shards-app-promo-page--1 .app-screenshot:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #e9ecef;
  opacity: 0.6;
}
.shards-app-promo-page--1 .app-screenshot img {
  max-width: 300px;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 13px 25px rgba(0, 0, 0, 0.05),
    0 60px 100px rgba(192, 192, 192, 0.5);
}
@media (max-width: 991.98px) {
  .shards-app-promo-page--1 .app-screenshot img {
    display: table;
    position: static;
    -webkit-transform: translate(0);
    transform: translate(0);
    margin: 0 auto;
  }
}
.shards-app-promo-page--1 .feature p {
  font-size: 16px;
}
.shards-app-promo-page--1 .feature .icon {
  border-radius: 50%;
  min-width: 65px;
  height: 65px;
  line-height: 65px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(33, 37, 41, 0.1), 0 7px 14px rgba(33, 37, 41, 0.1);
  font-size: 27px;
}
@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .feature .icon {
    margin-right: 1.5rem !important;
  }
}
.shards-app-promo-page--1 .blog .card-img-top {
  height: 100%;
}
.shards-app-promo-page--1 .section-invert {
  background: #f9fafc;
}
.shards-app-promo-page--1 .section-invert .card-title {
  font-weight: 700;
}
.shards-app-promo-page--1 .testimonials .avatar {
  max-width: 80px;
  overflow: hidden;
}
.shards-app-promo-page--1 .section {
  padding: 56px 44px;
}
.shards-app-promo-page--1 .section.bg-dark {
  background-image: linear-gradient(90deg, #1e1a2c 25%, #262136 25%, #262136 50%, #1e1a2c 50%, #1e1a2c 75%, #262136 75%, #262136 100%);
  background-size: 80.00px 80.00px;
  color: #fff;
}
.shards-app-promo-page--1 .section.bg-dark a {
  color: #fff;
}
.shards-app-promo-page--1 .section.bg-dark a:hover {
  color: rgba(255,0,66,1);
}
.shards-app-promo-page--1 .testimonials.section.bg-dark .section-title {
  color: #fff;
}

@media (max-width: 767.98px) {
  .shards-app-promo-page--1 .subscribe button,
  .shards-app-promo-page--1 .subscribe input {
    width: 100%;
  }
}
/*# sourceMappingURL=shards-extras.min.css.map */

.ant-modal-header {
  padding: 16px 24px;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  border-bottom: 1px solid #f0f0f0;
  border-radius: 2px 2px 0 0;
}

button.ant-modal-close{
  width:55px;
}
.ant-modal-close:hover {

  background: #d2c4be;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255,255,255,.7);
  font-size: 14px;
  font-weight: 600;
  text-transform: lowercase;
  margin: 0 8px;
  border: 1px solid transparent;
  border-radius: 5px;
  padding: 4px 16px;
}
.navbar-dark .navbar-nav .nav-link:hover {
  color: rgba(255,255,255,1);
  border: 1px solid rgba(255,255,255,.7)  ;
}
.text-muted {
  text-transform: lowercase;
}
.text-centered {
  text-align: center;
}
.bg-dark {
    background-color: #222136!important
}
footer .navbar-dark .navbar-brand {
  text-transform: lowercase;
}
.navbar.navbar-expand-md.navbar-dark {
  margin: 0px;
  background: transparent;
}
@media (min-width: 768px) {
  .navbar.navbar-expand-md.navbar-dark {
    margin: 0px 48px 96px;
  }
  .shards-app-promo-page--1 .section h3.section-title {
    text-align: center;
  }
  .shards-app-promo-page--1 .section h3.section-title {
    margin-bottom: 32px;
  }
  .shards-app-promo-page--1 .section.beneficios img {
    margin-top: -56px;
    margin-bottom: -56px;
    max-width: 108%;
  }
}
.app-features.section img{
  height: 100vh;
}
@media (max-width: 768px) {
  .navbar.navbar-expand-md.navbar-dark {
    margin-bottom: 12px;
  }
  .navbar-dark .navbar-nav .nav-link {
    padding: 16px;
    font-size: 16px;
    background: #222136;
    margin-bottom: 1px;
    opacity: .8;
  }
  .shards-app-promo-page--1 .feature p {
    margin: 0;
  }
  .shards-app-promo-page--1 .section {
    padding: 44px 20px;
  }
  .shards-app-promo-page--1 .section.welcome {
    min-height: auto;
    height: auto;
  }
  .shards-app-promo-page--1 .section h3.section-title {
    font-size: 24px;
    text-align: center;
    margin-bottom: 16px;
  }
  .shards-app-promo-page--1 .section h5 {
    font-size: 18px;
  }
  .shards-app-promo-page--1 .section.subscribe p.t-1{
    margin-top: 24px;
  }
  .shards-app-promo-page--1 .section.beneficios img {
    width: 122%;
    margin-bottom: -44px;
    left: -10%;
    margin-right: -10%;
    position: relative;
  } 
}

h1, h2, h3, h4, h5 {
  font-family: "Poppins";
  font-weight: 700;
}

p {
  line-height: 1.5em;
}
p.t-2 {
  font-size: 16px;
}

a.link {
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-image-source: linear-gradient(to left, rgba(255,0,66,1), rgba(233,127,0,1));
  padding: 2px 16px;
  color: #222;
}
a.link:hover {
  border-image-source: linear-gradient(to right, rgba(255,0,66,1), rgba(233,127,0,1));
}
.shards-app-promo-page--1 .section.beneficios {
  padding: 0;
}
.features-bg {
  background-image: url("/imagesHome/app-promo/Mi_Hosting.png");
  background-position: left;
  background-size: cover;
  position: initial;
}