.fontTextDescription{
    font-family: roboto;
    font-size: 17px;
    font-weight: 400;
}
.containerInfoDealerSectionDetail{
    position: relative;
    border-radius: 7px;
    border: 1px solid rgba(26, 32, 44, 0.3);
    padding: 25px;
    margin-bottom: 20px;
}
.containerInfoDealerSectionDetail h6{
    font-family: poppins;
}
.imagesOtrosSitios{
    width: 50px;
    border-radius: 50%;
    margin-left: 10px;
}

/* ACORDENES DE DATOS DEL AUTO */
.collapse-container-custom {
    display : flex;
  }
  
  .collapse-container-custom > .ant-collapse-item {
    border-bottom: none;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-right: 20px;
  }
  
  .collapse-panel-custom {
    display: flex;
    flex-direction: column;
    align-items: center;
    width : 100%;
}

.collapse-panel-custom > .ant-collapse-header {
    width: 100%;
}

.collapse-panel-custom > .ant-collapse-content {
    width: 100%;
    padding: 20px;
  }

.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
    color : black;
    box-shadow: none; 
}


@media (min-width: 320px) and (max-width: 767px) {
    .otrosSitiosContainer{
        display: none;
    }
    .descriptionContainerSectionDetail{
        width: 100%;
        text-align: justify;
    }
    .collapse-container-custom{
        display: flex;
        flex-direction: column;
    }
    .ant-collapse-header{
        margin: 10px 12px;
    }
    }