.slider-checkbox {
  position: relative;
}
.slider-checkbox input {
  margin: 0px;
  margin-top: 1px;
  cursor: pointer;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  -moz-opacity: 0;
  -khtml-opacity: 0;
  opacity: 0;
  position: absolute;
  z-index: 1;
  top: 0px;
  left: 0px;
  background: red;
  width: 40px;
  height: 20px;
}
.slider-checkbox input:checked + .label:before {
  background-color: #1877c1;
  content: "\f00c";
  padding-left: 6px;
}
.slider-checkbox input:checked + .label:after {
  left: 21px;
}
.slider-checkbox .label {
  position: relative;
  padding-left: 46px;
}
.slider-checkbox .label:before, .slider-checkbox .label:after {
  position: absolute;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  transition: background-color 0.3s, left 0.3s;
}
.slider-checkbox .label:before {
  content: "\f00d";
  color: #fff;
  box-sizing: border-box;
  font-family: 'FontAwesome', sans-serif;
  padding-left: 23px;
  font-size: 0px;
  line-height: 20px;
  background-color: #888;
  left: 0px;
  top: 0px;
  height: 20px;
  width: 40px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.slider-checkbox .label:after {
  content: "";
  letter-spacing: 20px;
  background: #fff;
  left: 1px;
  top: 1px;
  height: 18px;
  width: 18px;
}
#wrapper {
  box-sizing: border-box;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  border-radius: 12px;
  padding: 12px 24px;
  width: 720px;
  margin: 12px auto;
  background: #fff;
}
p.center {
  text-align: center;
}
.slider-checkbox {
  margin-top: 12px;
  margin-bottom: 12px;
}
.slider-checkbox input:checked + .label[for="c1"]:before, .slider-checkbox input:not(:checked) + .label[for="c1"]:before {
  background-color: #c21717;
}
.slider-checkbox input:checked + .label[for="c2"]:before {
  background-color: #ffdc00;
}
.slider-checkbox input:not(:checked) + .label[for="c3"]:before {
  background-color: #0a4776;
}

.red-snow {
  color: #c21717;
}

.modal-backdrop {
  background: #000;
}

.modal-backdrop.show {
  opacity: .8;
}

.custom-radio .custom-control-input:checked~.custom-control-label::before {
  background: rgb(255,0,66);
}
.dropdown-menu {
  right: 15px;
}