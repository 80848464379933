
.trr {
    margin-top: 12px;
    margin-bottom: 12px !important;
} 

table {
    width:100%
}  

.down {
    color: #db3811 !important;
}

.up {
    color: #109618 !important;
    transform: rotate(-45deg) !important;
    margin-top: -6px !important;
}

.padding_flex {
    display: inline-flex;
    padding-top: 10px;
    padding-bottom: 10px;
}

[class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow1"] {
    width: 12px;
    height: 12px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 5px;
  }
  [class*="icono-arrow1"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    transform: rotate(-45deg);
    width: 23px;
    transform-origin: right top;
  }
  [class*="icono-arrow1"][class*="-left"] {
    transform: rotate(45deg);
  }
  [class*="icono-arrow1"][class*="-left"][class*="-up"] {
    transform: none;
  }
  [class*="icono-arrow1"][class*="-left"][class*="-down"] {
    transform: rotate(90deg);
  }
  [class*="icono-arrow1"][class*="-right"] {
    transform: rotate(-135deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-up"] {
    transform: rotate(-90deg);
  }
  [class*="icono-arrow1"][class*="-right"][class*="-down"] {
    transform: rotate(180deg);
  }
  [class*="icono-arrow1"][class*="-up"] {
    transform: rotate(-45deg);
  }
  [class*="icono-arrow1"][class*="-down"] {
    transform: rotate(135deg);
  }
  [class*="icono-arrow2"] {
    width: 0;
    height: 0;
    border-width: 6px;
    border-style: solid;
    border-bottom-color: transparent;
    border-left-color: transparent;
    margin: 10px;
  }
  [class*="icono-arrow2"]:before {
    right: 0;
    top: -3px;
    position: absolute;
    height: 4px;
    box-shadow: inset 0 0 0 32px;
    transform: rotate(-45deg);
    width: 15px;
    transform-origin: right top;
  }
  [class*="icono-arrow2"][class*="-left"] {
    transform: rotate(45deg);
  }
  [class*="icono-arrow2"][class*="-left"][class*="-up"] {
    transform: none;
  }
  [class*="icono-arrow2"][class*="-left"][class*="-down"] {
    transform: rotate(90deg);
  }
  [class*="icono-arrow2"][class*="-right"] {
    transform: rotate(-135deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-up"] {
    transform: rotate(-90deg);
  }
  [class*="icono-arrow2"][class*="-right"][class*="-down"] {
    transform: rotate(180deg);
  }
  [class*="icono-arrow2"][class*="-up"] {
    transform: rotate(-45deg);
  }
  [class*="icono-arrow2"][class*="-down"] {
    transform: rotate(135deg);
  }
  [class*="icono"] {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    color: #666;
    box-sizing: border-box;
  }
  [class*="icono"]:after,
  [class*="icono"]:before {
    content: "";
    box-sizing: border-box;
  }
  [class*="icono-arrow3"] {
    width: 18px;
    height: 20px;
    border-width: 4px 4px 0 0;
    border-style: solid;
    margin: 10px;
    border-radius: 0 2px 0 0;
  }
  [class*="icono-arrow3"]:before,
  [class*="icono-arrow3"]:after {
    border-radius: 2px;
    position: absolute;
  }
  [class*="icono-arrow3"]:before {
    right: 0;
    top: -3px;
    width: 23px;
    height: 4px;
    transform: rotate(-45deg);
    transform-origin: right top;
    box-shadow: inset 0 0 0 32px;
  }
  [class*="icono-arrow3"]:after {
    width: 4px;
    height: 4px;
    left: -2px;
    top: -4px;
    box-shadow: inset 0 0 0 32px, 16px 17px;
  }
  [class*="icono-arrow3"][class*="-left"] {
    transform: rotate(45deg);
  }
  [class*="icono-arrow3"][class*="-left"][class*="-up"] {
    transform: none;
  }
  [class*="icono-arrow3"][class*="-left"][class*="-down"] {
    transform: rotate(90deg);
  }
  [class*="icono-arrow3"][class*="-right"] {
    transform: rotate(-135deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-up"] {
    transform: rotate(-90deg);
  }
  [class*="icono-arrow3"][class*="-right"][class*="-down"] {
    transform: rotate(180deg);
  }
  [class*="icono-arrow3"][class*="-up"] {
    transform: rotate(-45deg);
  }
  [class*="icono-arrow3"][class*="-down"] {
    transform: rotate(135deg);
  }
  [class*="icono-arrow4"] {
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-top: 7px solid;
    border-right: 7px solid;
    margin: 15px;
  }
  [class*="icono-arrow4"][class*="-left"] {
    transform: rotate(45deg);
  }
  [class*="icono-arrow4"][class*="-left"][class*="-up"] {
    transform: none;
  }
  [class*="icono-arrow4"][class*="-left"][class*="-down"] {
    transform: rotate(90deg);
  }
  [class*="icono-arrow4"][class*="-right"] {
    transform: rotate(-135deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-up"] {
    transform: rotate(-90deg);
  }
  [class*="icono-arrow4"][class*="-right"][class*="-down"] {
    transform: rotate(180deg);
  }
  [class*="icono-arrow4"][class*="-up"] {
    transform: rotate(-45deg);
  }
  [class*="icono-arrow4"][class*="-down"] {
    transform: rotate(135deg);
  }
  