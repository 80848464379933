.content-inputCustomFile {
  padding: 20px;
  border: 1px dashed rgb(154, 154, 154);
}
.inputCustomFile {
  height: 60px;
  width: 100%;
  display: flex;
  border: 1px dashed #d9d9d9;
  margin: 0px 10px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-size: 16px;
  background: #f9f2f35e;
}
.left {
  float: left;
}
.Icon {
  opacity: 0.3;
  height: 84px;
  width: 84px;
}

.FileInput {
  display: none;
}

/* Images */
.file-list {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.file-item {
  margin: 10px;
  cursor: move;
  transition: all 0.2s linear;
}
.ant-upload-list-item,
.ant-upload-list-item-actions .anticon-delete {
  margin: 0 !important;
  padding: 0 !important;
  border: none 0 !important;
  font-size: 20px !important;
  color: #f39b19 !important;
}

/* Tablets */
@media (max-width: 1000px) {
}

/* Mobiles */
@media (max-width: 640px) {
  .inputCustomFile {
    font-size: 11px;
  }
  .file-item {
    width: 50%;
    height: 150px;
    float: left;
    max-height: 250px;
  }
  .ant-upload-list-picture-card .ant-upload-list-item {
    float: left;
    width: 100%;
    height: 150px;
  }
}
