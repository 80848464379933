@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,400;1,600;1,700&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Poppins", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.App {
  text-align: center;
}
.paddingsButtons{
  padding-bottom: 40px;
}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  max-width: 50px;
}
.itemDash {
  max-width: 14%;
  display: inline-block;
  margin: 5px 0px 0px !important;
  padding-top: 5px;
  padding-bottom: 5px;
  text-align: center !important;
  width: 100%;
  font-weight: bold;
}

.itemFB {
  border: 1px solid rgb(2, 151, 196);
  color: rgb(2, 151, 196);
  border-radius: 5px 0px 0px 5px;
}

.itemOLX {
  border: 1px solid rgb(152, 1, 152);
  color: rgb(152, 1, 152);
}

.itemAC {
  border: 1px solid rgb(15, 149, 23);
  color: rgb(15, 149, 23);
}

.itemDM {
  border: 1px solid rgb(254, 152, 1);
  color: rgb(254, 152, 1);
}

.itemML {
  border: 1px solid rgb(219, 56, 17);
  color: rgb(219, 56, 17);
}

.itemIG {
  border: 1px solid rgb(219, 203, 16);
  color: rgb(219, 203, 16);
}

.itemNA {
  border: 1px solid rgb(50, 101, 202);
  color: rgb(50, 101, 202);
  border-radius: 0px 5px 5px 0px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.ant-collapse-content-box {
  padding: 0px !important;
  border-top: 0px !important;
}
.App-link {
  color: #61dafb;
}

@media print {
  .noprinter {
    display: none !important;
  }

  .noprintercs {
    margin-left: 0px !important;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-spin-nested-loading > div > .ant-spin {
  background: #fff;
  opacity: 0.4;
  position: fixed;
}
.ant-spin-blur::after {
  background: #fff;
  opacity: 0.4;
}

.ant-table-pagination {
  float: none !important;
}

.ant-pagination {
  text-align: center;
  padding-bottom: 6px;
}
.carta_vinculacion {
  font-size: 12px;
  margin-bottom: 5px;
  margin-top: -7px;
}
@media (min-width: 320px) and (max-width: 767px) {
  .cardealertext {
    max-width: 105px;
  }
  .cardealertext2 {
    white-space: pre-wrap;
  }
}
/* Layout */
main {
  background: #f1f1f1;
}

.btn,
.ant-btn {
  font-weight: 400;
  font-family: "Poppins";
  border: 1px solid transparent;
  padding: 0.85rem;
  font-size: 1rem;
  line-height: 1rem;
  border-radius: 0.25rem;
  transition: all 250ms cubic-bezier(0.27, 0.01, 0.38, 1.06);
  height: auto;
  color: #f1f1f1;
  white-space: unset;
}
.btn,
.ant-btn,
.btn-secondary {
  border: none;
  background: #ccc;
  background: linear-gradient(
    124deg,
    rgba(111, 111, 111, 1) 0%,
    rgba(122, 122, 122, 1) 100%
  );
}
.btn:hover,
.ant-btn:hover,
.btn-secondary:hover {
  color: #f1f1f1;
  border: none;
  background: #ccc;
  background: linear-gradient(
    124deg,
    rgba(122, 122, 122, 1) 0%,
    rgba(111, 111, 111, 1) 100%
  );
}
.btn.btn-primary,
.ant-btn.ant-btn-primary {
  border: none;
  background: rgb(255, 0, 66);
  background: linear-gradient(
    124deg,
    rgba(255, 0, 66, 1) 0%,
    rgba(233, 127, 0, 1) 100%
  );
}
.btn.btn-primary:hover,
.ant-btn.ant-btn-primary:hover,
.bg-primary,
.btn.btn-primary:active,
.ant-btn.ant-btn-primary:active,
.badge-primary {
  background: linear-gradient(
    124deg,
    rgba(233, 127, 0, 1) 0%,
    rgba(255, 0, 66, 1) 100%
  );
}
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active,
.show > .btn-primary.dropdown-toggle {
  background: #3d5170;
}
a {
  color: rgba(255, 0, 66, 1);
  font-weight: 700;
}
a:hover {
  color: #e97f00;
  font-weight: 700;
}
.main-sidebar .nav .nav-item .nav-link.active,
.main-sidebar .nav .nav-item .nav-link:hover,
.main-sidebar .nav .nav-item.active,
.main-sidebar .nav .nav-item:hover {
  box-shadow: inset 0.1875rem 0 0 rgb(255, 0, 66);
  background-color: #fbfbfb;
  color: rgba(255, 0, 66, 1);
}
.main-sidebar .nav .nav-item .nav-link.active i,
.main-sidebar .nav .nav-item .nav-link:hover i,
.main-sidebar .nav .nav-item.active i,
.main-sidebar .nav .nav-item:hover i {
  color: rgba(255, 0, 66, 1);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header:hover {
  box-shadow: inset 0.1875rem 0 0 rgba(255, 0, 66, 1);
  background-color: #fbfbfb;
  color: rgba(255, 0, 66, 1);
}
h1,
h2,
h3,
h4,
h5,
. {
  font-family: "Poppins";
  color: #212c3d;
  font-weight: 700;
}
.ant-modal-root .ant-modal-content .ant-modal-title {
  font-family: "Poppins";
  color: #212c3d;
  font-weight: 700;
  font-size: 22px;
}
.ant-input {
  height: 44px;
}
.ant-form-item {
  margin-bottom: 12px;
}

/* Common Styles */
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5em;
  color: #3d5170;
  font-weight: 700;
  line-height: 1;
}
.t-1 {
  font-weight: normal;
  font-size: 16px;
  margin-bottom: 8px;
  line-height: 1.8em;
}
.t-2 {
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 8px;
  color: #3d5170;
}
.t-3 {
  color: #333;
  font-weight: initial;
  font-size: 0.875em;
  line-height: 1.2em;
}
.t-4 {
  font-size: 0.75em;
}
.fw-400 {
  font-weight: 400;
}
.c-blue {
  color: #3d5170;
}
.react-card-flipper,
.react-card-flip {
  background: transparent;
  border-radius: 5px;
  box-shadow: none;
}
.card {
  box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 2px 4px rgba(90, 97, 105, 0.12),
    0 5px 5px rgba(90, 97, 105, 0.06), 0 3px 35px rgba(90, 97, 105, 0.1);
  background: #fff;
}
.card-header h1,
.card-header h2,
.card-header h3,
.card-header h4,
.card-header h5,
.card-header h6 {
  font-weight: 600;
  margin: 0 !important;
}

.page-header .page-title {
  font-weight: 700;
}
.navbar-expand-md .navbar-collapse {
  justify-content: center;
}
.ant-collapse > .ant-collapse-item:last-child,
.ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header,
.ant-collapse {
  border-radius: 0;
}
.card {
  border-radius: 5px;
  overflow: hidden;
}
.card-img {
  border-radius: unset;
}
.card-detail {
  min-height: 150px;
}
.ellipsis {
  max-width: 100%;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
  white-space: nowrap;
}
/* User Menu */
.dropdown-menu-small .dropdown-item:hover,
.dropdown-menu-small .dropdown-item,
.main-navbar .navbar .nav-link {
  font-weight: 400;
  color: #3d5170;
}
.dropdown-menu-small .dropdown-item:hover,
.main-navbar .navbar .nav-link:hover {
  color: rgba(255, 0, 66, 1);
}
.shards-app-promo-page--1 .testimonials .testimonial .avatar {
  max-width: 80px;
  max-height: 80px;
}
.shards-app-promo-page--1 .testimonials .testimonial .avatar img {
  width: 100%;
  border-radius: 5px;
}
@media (max-width: 767.98px) {
  .page-header .page-title {
    font-size: 1.5em;
    text-align: left;
    margin-bottom: 16px;
  }
}

.z_index_min {
  z-index: 92099 !important;
}

.z_index_max {
  z-index: 93000 !important;
}

.ant-select-dropdown {
  z-index: 93001 !important;
}

/* NavBar test */
.main-sidebar {
  background: #fafafa;
}
.ant-collapse {
  background: transparent;
  color: #3d5170;
  border: none;
}
.ant-collapse > .ant-collapse-item {
  border: none;
}
.main-sidebar .nav .nav-item .nav-link,
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #3d5170;
  background: transparent;
  font-weight: 500;
}
.main-sidebar .nav .nav-item .nav-link,
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  padding: 0.9375rem 1.5625rem;
  border-bottom: 1px solid #eee;
  border-top: 1px solid #fff;
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header .ant-collapse-arrow {
  right: 26px;
  left: unset;
}
.main-sidebar .nav .nav-item .nav-link i {
  color: #3d5170;
}
.ant-collapse-content {
  border-top: none;
}
.ant-collapse
  > .ant-collapse-item
  .ant-collapse-content-box
  .nav-item
  .nav-link {
  font-weight: 400;
  font-size: 14px;
}

@media (min-width: 991.98px) {
  .main-sidebar .nav .nav-item .nav-link i.material-icons {
    font-size: 1.3rem;
  }
}

@media (max-width: 991.98px) {
  .main-sidebar .navbar-brand {
    font-size: 20px;
  }
}

@media (max-width: 767.98px) {
  .main-sidebar .nav .nav-item .nav-link,
  .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    font-size: 22px;
    font-weight: 400;
  }
  .main-sidebar .nav .nav-item .nav-link i {
    font-size: 1.5rem;
  }
  .ant-collapse
    > .ant-collapse-item
    .ant-collapse-content-box
    .nav-item
    .nav-link {
    font-size: 16px;
  }
}
.main-sidebar .navbar-brand {
  font-size: 1.35rem;
}

.ant-dropdown-placement-bottomRight {
  z-index: 1070;
}

.card_message_1 {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 25px;
  color: #000;
  background-color: #e6e7ec;
  max-width: 600px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: 15px 0;
  word-break: break-all;
  transform: rotate(180deg);
  direction: ltr;
}

.chat_wrapper { 
  display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column-reverse;
    flex-direction: column-reverse;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

.chabox {
  width: 100%;
    max-width: 800px;
    height: calc(60vh - 50px);
    min-height: 100%;
    padding: 15px 30px;
    margin: 0 auto;
    /*overflow-y: scroll;*/
    background-color: #fff;
    transform: rotate(180deg);
    direction: rtl;
}

.mj-100 {
  width: 100%;
    align-items: center;
    justify-content: center;
}
.circuloEN {
     width: 30px;
     height: 30px;
     -moz-border-radius: 50%;
     -webkit-border-radius: 50%;
     border-radius: 50%;
     background: #5cb85c;
     border: 1px solid gray;
}

.bg-leido {
  background: #eee;
}
.circuloDI {
  width: 30px;
  height: 30px;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border: 1px solid gray;
  border-radius: 50%;
  background: #8f0c0c;
}


.card_message_2 {
  font-size: 18px;
  padding: 10px 20px;
  border-radius: 25px;
  color: #000;
  background-color: #e6e7ec;
  max-width: 600px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  position: relative;
  margin: 15px 0;
  word-break: break-all;
  transform: rotate(180deg);
  direction: ltr;
  color: #fff;
  margin-left: auto;
  background-color: #00a9de;
}

