.modalShow{
    display: none;
}
.modalQrToPrint{
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding-top: 100px;
}
.ModalQrRender{
    padding: 20px;
}
.QrGenerate{
    display: flex;
    justify-content: center;
    align-items: center;
}
.ButtonToPrintQR{
    width: 100%;
    display: flex;
    justify-content: center;
}
@media (max-width: 575.98px) {
    .containerQr {
        display: flex !important;
        width: 100%;
        flex-direction: column !important;
        justify-content:center ;
        align-items: center;
    }
    .QrGenerate{
        width: 100%;
    }
    .infoDealerQrContainer{
        margin-top: 20px;
        width: 100%;
    }
    .ant-modal-content{
    margin-top: 75px !important;
    }
}